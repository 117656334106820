import API from "src/utils/api";
import {
  PlaybackMetric,
  InSessionResponse,
  ContentStatus,
  VideoData,
} from "src/interfaces/LiveMediaInterfaces";
import * as Sentry from "@sentry/browser";

export async function getContentStatus(
  contentId: string,
  authHeaders: any
): Promise<ContentStatus | null> {
  try {
    const contentStatus = await API.get(
      `/liveInSession/contentStatus/${contentId}`,
      { cityId: "Other", ...authHeaders }
    );

    return contentStatus && contentStatus.data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return null;
  }
}

export async function getVideoData(
  contentId: string,
  contentType: string,
  authHeaders: any
): Promise<VideoData | null> {
  try {
    const videoData = await API.get(
      `/digital/getVideoData?contentId=${contentId}&contentType=${contentType}`,
      { cityId: "Other", ...authHeaders }
    );

    return videoData && videoData.data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return null;
  }
}

export async function pushPlaybackMetric(
  playbackMetric: PlaybackMetric,
  responseRequired: boolean,
  authHeaders: any
): Promise<InSessionResponse | null> {
  try {
    const resp = await API.post(
      "/liveInSession/playbackMetrics",
      { playbackMetric, responseRequired },
      { cityId: "Other", ...authHeaders }
    );
    return resp && resp.data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return null;
  }
}
