import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import throttle from "lodash.throttle";
import * as Sentry from "@sentry/browser";

import { generateUuid } from "src/utils/uuid";
import { getVideoData, pushPlaybackMetric } from "src/api/live";
import {
  UserData,
  ContentData,
  PlatformData,
  EnergyData,
  RankData,
} from "src/interfaces/LiveMediaInterfaces";

import "./styles.css";

const lottie_red_bolt = require("src/resources/animations/lottie_red_bolt.json");
const lottie_yellow_bolt = require("src/resources/animations/lottie_yellow_bolt.json");

const PROTOCOL = "urn:x-cast:fit.cure.app";

const sendPlaybackMetrics = throttle(pushPlaybackMetric, 1000);
const throttledSendPlaybackMetrics = throttle(pushPlaybackMetric, 25000);

const setDocumentCookies = (cookies: any = {}) => {
  Object.keys(cookies).forEach((cname) => {
    document.cookie = `${cname}=${cookies[cname]};path=/;domain=.cure.fit`;
  });
};

let GamificationView: any = null;
import("@curefit/cult-live-component-library")
  .then((lib) => {
    GamificationView = lib.GamificationView;
  })
  .catch(console.error);

const Player = () => {
  const [showGamification, setShowGamification] = useState(false);
  const [startTimeEpoch, setStartTimeEpoch] = useState(0);

  const [user, setUser] = useState<UserData>();
  const [content, setContent] = useState<ContentData>();
  const [platform, setPlatform] = useState<PlatformData>();
  const [rank, setRank] = useState<RankData>();
  const [energy, setEnergy] = useState<EnergyData>();
  const [userCount, setUserCount] = useState("");
  const [watchVitals, setWatchVitals] = useState({});

  const playbackID = useRef(generateUuid());
  const bitrate = useRef<number>();
  const buffering = useRef<boolean>();
  const eventTimeout = useRef<NodeJS.Timeout>();

  const context = cast.framework.CastReceiverContext.getInstance();
  const playerManager = context.getPlayerManager();

  const getPlayerMetricData = useCallback(
    (eventType) => {
      return {
        eventType,
        nwType: "wifi",
        playbackType: "LIVE",
        casting: "chromecast",
        ID: generateUuid(),
        ctsEpoch: Date.now(),
        userId: user?.userId || "",
        userSessionId: content?.userSessionId || null,
        contentId: content?.contentId || "",
        contentType: content?.contentType || "LIVE",
        playbackOffset: playerManager.getCurrentTimeSec(),
        playbackBandwidth: bitrate.current || 0,
        platform: platform ? JSON.stringify(platform) : null,
        isBuffering: Boolean(buffering.current),
        playbackID: playbackID.current,
      };
    },
    [user, content, platform, playerManager]
  );

  const playerErrorHandler = useCallback(
    (event) => {
      const { reason } = event;
      console.log("ERROR", reason);
      if (user?.userId && content?.contentId) {
        const data = getPlayerMetricData("ERROR");
        sendPlaybackMetrics(
          { ...data, error: reason },
          false,
          user?.sessionHeaders
        );
      }
    },
    [getPlayerMetricData, user, content]
  );

  const throttledSeek = useMemo(() => {
    function seekTo(time: number) {
      console.log("Seeking to", time);
      playerManager.seek(time);
    }

    return throttle(seekTo, 1000);
  }, [playerManager]);

  const playerTimeUpdateHandler = useCallback(
    (event) => {
      const { currentMediaTime } = event; // sec

      if (startTimeEpoch > 0) {
        const correctTime = (Date.now() - startTimeEpoch) / 1000;
        const diff = correctTime - currentMediaTime;
        if (Math.abs(diff) > 10) {
          console.log("Should seek to", correctTime, "from", currentMediaTime);
          throttledSeek(correctTime);
        }
      }

      const data = getPlayerMetricData("PLAYBACK_METADATA");
      if (user?.userId && content?.contentId) {
        throttledSendPlaybackMetrics(data, false, user?.sessionHeaders);
      }
    },
    [user, content, getPlayerMetricData, startTimeEpoch, throttledSeek]
  );

  const playerBufferingHandler = useCallback(
    (event) => {
      const { isBuffering } = event;
      buffering.current = isBuffering;
      console.log("BUFFER", isBuffering);
      if (user?.userId && content?.contentId) {
        const data = getPlayerMetricData("BUFFER");
        sendPlaybackMetrics(data, false, user?.sessionHeaders);
      }
    },
    [getPlayerMetricData, user, content]
  );

  const playerBitrateChangedHandler = useCallback((event) => {
    const { totalBitrate } = event;
    bitrate.current = totalBitrate;
  }, []);

  const customMessageHandler = useCallback((event) => {
    const { data } = event;
    console.log(data.type, data);

    if (data.type === "USER") {
      // legacy: data contains properties from both types
      setUser(data);
      setContent(data);
      Sentry.setUser({ id: data.userId });
    }

    if (data.type === "PLATFORM") {
      setPlatform(data);
      Sentry.setContext("platform", data);
    }

    if (data.type === "RANK") {
      setRank(data);
    }

    if (data.type === "ENERGY") {
      setEnergy(data);

      // hide gamification layer if no new events in timeout
      setShowGamification(true);
      if (eventTimeout.current) clearTimeout(eventTimeout.current);
      eventTimeout.current = setTimeout(() => setShowGamification(false), 5000);
    }

    if (data.type === "LIVE_COUNT") {
      setUserCount(data.userCount);
    }

    if (data.type === "WATCH_VITALS") {
      setWatchVitals((watchVitals) => ({
        ...watchVitals,
        ...data.watchVitals,
      }));
    }
  }, []);

  // fetch cookies and start time of live video
  useEffect(() => {
    if (content?.contentId && user?.sessionHeaders) {
      getVideoData(
        content?.contentId,
        content?.contentType || "LIVE",
        user?.sessionHeaders
      )
        .then((response) => {
          setDocumentCookies(response?.streamDetails?.cookies);
          if (response?.streamType === "VOD") {
            const currentOffsetMs = response?.streamDetails?.currentOffsetMs;
            if (currentOffsetMs) {
              setStartTimeEpoch(Date.now() - currentOffsetMs);
            }
          }
        })
        .catch((err) => {
          setContent({ ...content }); // force a retry
          console.error(err);
          Sentry.captureException(err);
        });
    }
  }, [user, content]);

  // configure context
  useEffect(() => {
    const options = new cast.framework.CastReceiverOptions();
    options.customNamespaces = Object.assign({});
    options.customNamespaces[PROTOCOL] = "JSON";

    context.addCustomMessageListener(PROTOCOL, customMessageHandler);
    context.start(options);

    return () => {
      context.removeCustomMessageListener(PROTOCOL, customMessageHandler);
      if (eventTimeout.current) clearTimeout(eventTimeout.current);
    };
  }, [context, customMessageHandler, playerManager]);

  // configure player manager
  useEffect(() => {
    playerManager.setMessageInterceptor(
      cast.framework.messages.MessageType.LOAD,
      (data) => {
        console.log(data.type, data);

        const isSameDomainContent =
          data.media?.contentId?.indexOf("vod.cure.fit") >= 0 ||
          data.media?.contentId?.indexOf("videos.cure.fit") >= 0;

        const playbackConfig = Object.assign(
          new cast.framework.PlaybackConfig(),
          playerManager.getPlaybackConfig()
        );
        playbackConfig.autoResumeDuration = 5;

        if (isSameDomainContent) {
          playbackConfig.manifestRequestHandler = (r) =>
            (r.withCredentials = true);
          playbackConfig.segmentRequestHandler = (r) =>
            (r.withCredentials = true);
          playbackConfig.licenseRequestHandler = (r) =>
            (r.withCredentials = true);
        }

        playerManager.setPlaybackConfig(playbackConfig);

        if (data.media.customData) setContent(data.media.customData);
        if (data.customData?.user) setUser(data.customData?.user);
        if (data.customData?.platform) setPlatform(data.customData?.platform);

        Sentry.setContext("load", data);

        // adding a log to debug issues
        const currentContentId = playerManager.getMediaInformation().contentId;
        if (data.media.contentId && data.media.contentId === currentContentId) {
          console.log("Media load event is same as what is playing.");
          console.log(currentContentId, data.media.contentId);
        }

        return data;
      }
    );
  }, [context, playerManager]);

  // configure playback error handler
  useEffect(() => {
    playerManager.addEventListener(
      cast.framework.events.EventType.ERROR,
      playerErrorHandler
    );

    return () => {
      playerManager.removeEventListener(
        cast.framework.events.EventType.ERROR,
        playerErrorHandler
      );
    };
  }, [playerManager, playerErrorHandler]);

  // configure playback time update handler
  useEffect(() => {
    playerManager.addEventListener(
      cast.framework.events.EventType.TIME_UPDATE,
      playerTimeUpdateHandler
    );

    return () => {
      playerManager.removeEventListener(
        cast.framework.events.EventType.TIME_UPDATE,
        playerTimeUpdateHandler
      );
    };
  }, [playerManager, playerTimeUpdateHandler]);

  // configure playback buffering handler
  useEffect(() => {
    playerManager.addEventListener(
      cast.framework.events.EventType.BUFFERING,
      playerBufferingHandler
    );

    return () => {
      playerManager.removeEventListener(
        cast.framework.events.EventType.BUFFERING,
        playerBufferingHandler
      );
    };
  }, [playerManager, playerBufferingHandler]);

  // configure playback bitrate changed handler
  useEffect(() => {
    playerManager.addEventListener(
      cast.framework.events.EventType.BITRATE_CHANGED,
      playerBitrateChangedHandler
    );

    return () => {
      playerManager.removeEventListener(
        cast.framework.events.EventType.BITRATE_CHANGED,
        playerBitrateChangedHandler
      );
    };
  }, [playerManager, playerBitrateChangedHandler]);

  if (!GamificationView) return null;
  if (!showGamification) return null;

  return (
    <GamificationView
      isCastEnv
      livePanelInfoConfig={{
        startTimeEpoch: startTimeEpoch || 0,
        liveUserCount: userCount ? String(userCount) : null,
        hideLiveBadge: content?.contentType === "DIY_FITNESS", // reverse condition due to older apps not sending this field
      }}
      energyMeterConfig={{
        energyValue: energy?.energyValue || 0,
        lottieAnimations: {
          lottie_red_bolt,
          lottie_yellow_bolt,
        },
        muted: true,
      }}
      scoreScaleConfig={{
        contentId: content?.contentId || "",
        userId: user?.userId || "",
        adjacentUsers: rank?.adjacentUsers || [],
        rank: rank?.rank || 0,
        self: {
          userId: user?.userId || "",
          rank: rank?.rank || 0,
          profilePictureUrl: user?.profilePictureUrl || "",
          score: energy?.score || 0,
        },
        activityScore: energy?.score || 0,
      }}
      watchVitals={watchVitals}
    />
  );
};

export default Player;
