import axios from "axios";

const configs = {
  API_KEY: "9d153009-e961-4718-a343-2a36b0a1d1fd",
  API_BASE_URL: "https://www.cure.fit/api",
};

const baseHeaders = {
  appVersion: 7,
  osName: "browser",
  apikey: configs.API_KEY,
};

class API {
  static get = (url: string, headers: object) => {
    return API.xhr("get", url, headers);
  };

  static post = (url: string, data: any, headers: object) => {
    return API.xhr("post", url, headers, data);
  };

  static xhr = (
    method: any,
    url: string,
    headers: any,
    data?: { [index: string]: string }
  ) => {
    return axios({
      method,
      url,
      data,
      timeout: 10000,
      baseURL: configs.API_BASE_URL,
      headers: { ...baseHeaders, ...headers },
    });
  };
}

export default API;
